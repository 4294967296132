import { UserFormDto, UserTableDto } from '@/models/User';
import { isObjectValid, required, validateEmail, ValidationResult } from './form';

export interface UserFormValidity {
  name: ValidationResult;
  surname: ValidationResult;
  email: ValidationResult;
  roles: ValidationResult;
  language: ValidationResult;
  timezone: ValidationResult;
}

export function isFormValid(form: UserFormValidity) {
  return isObjectValid<UserFormValidity>(form);
}

export function createUserForm(user: UserFormDto, userList: UserTableDto[] | undefined) {
  const name = required(user.name);
  const surname = required(user.surname);
  const email = validateEmail(
    user.email,
    'messages.user-exist',
    userList?.map((item: UserTableDto) => item.email),
  );
  const roles = required(user.roles);
  const language = required(user.language);
  const timezone = required(user.timezone);

  return {
    name,
    surname,
    email,
    roles,
    language,
    timezone,
  };
}

export function updateUserForm(user: UserFormDto) {
  const name = required(user.name);
  const surname = required(user.surname);
  const roles = required(user.roles);
  const language = required(user.language);
  const timezone = required(user.timezone);
  const email = required(user.email);

  return {
    name,
    surname,
    roles,
    language,
    timezone,
    email,
  };
}
