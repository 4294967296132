
import { defineComponent, computed, ref, PropType } from 'vue';
import { FilterMatchMode } from 'primevue/api';
import { UserRole, UserTableDto } from '@/models/User';
import { useI18n } from 'vue-i18n';
import { useProfileStore } from '@/store/profile.module';

const proffesionalRoles = [UserRole.ADMIN as string, UserRole.RECEPTIONIST as string, UserRole.SPECIALIST as string];

export default defineComponent({
  emits: ['edit-user-click', 'delete-user-click'],
  props: {
    users: {
      type: Array as PropType<UserTableDto[]>,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const storeProfile = useProfileStore();
    const userId = computed(() => storeProfile.userId);
    const showPaginator = computed(() => props.users && props.users.length > 10);
    const roleOptions = ref([
      { label: t('common.all'), value: undefined },
      { label: t(`roles.${UserRole.ADMIN}`), value: UserRole.ADMIN },
      { label: t(`roles.${UserRole.RECEPTIONIST}`), value: UserRole.RECEPTIONIST },
      { label: t(`roles.${UserRole.SPECIALIST}`), value: UserRole.SPECIALIST },
    ]);
    const filters = ref();
    const initFilters = () => {
      filters.value = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        roles: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    };
    initFilters();

    const onEditUser = (user: UserTableDto) => {
      emit('edit-user-click', user.id);
    };
    const onDeleteUser = (user: UserTableDto) => {
      emit('delete-user-click', user.id, user.professionalId);
    };

    const renderRoles = (roles: string[]) => {
      const translatedRoles = roles
        .filter((role) => proffesionalRoles.includes(role))
        .map((role) => t(`roles.${role}`));
      return translatedRoles.join(', ');
    };
    return { filters, showPaginator, roleOptions, userId, initFilters, onEditUser, onDeleteUser, renderRoles };
  },
});
