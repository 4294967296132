import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "l-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_AppHeading = _resolveComponent("AppHeading")!
  const _component_UserTable = _resolveComponent("UserTable")!
  const _component_UserCreateModifyModal = _resolveComponent("UserCreateModifyModal")!
  const _component_UserDeleteModal = _resolveComponent("UserDeleteModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AppHeading, {
      title: _ctx.$t(_ctx.route.meta.breadcrumb ? _ctx.route.meta.breadcrumb[0].label : '')
    }, {
      right: _withCtx(() => [
        _createVNode(_component_Button, {
          class: "p-button p-button-icon",
          onClick: _ctx.onAddUser
        }, {
          default: _withCtx(() => [
            _cache[0] || (_cache[0] = _createElementVNode("i", {
              class: "icon-plus",
              "aria-hidden": "true"
            }, null, -1)),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('user.new')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 1
    }, 8, ["title"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_UserTable, {
        users: _ctx.users,
        onEditUserClick: _ctx.onEditUser,
        onDeleteUserClick: _ctx.onDeleteUser
      }, null, 8, ["users", "onEditUserClick", "onDeleteUserClick"])
    ]),
    _createVNode(_component_UserCreateModifyModal, {
      ref: "userModalRef",
      user: _ctx.modalUser,
      userId: _ctx.modalUserId,
      modify: _ctx.isEditModal,
      userList: _ctx.users,
      onUserUpdated: _ctx.loadUsers
    }, null, 8, ["user", "userId", "modify", "userList", "onUserUpdated"]),
    _createVNode(_component_UserDeleteModal, {
      ref: "userDeleteModalRef",
      user: _ctx.modalUser,
      userId: _ctx.modalUserId,
      userList: _ctx.users,
      patients: _ctx.modalProfessionalPatientsList,
      onUserDeleted: _ctx.loadUsers
    }, null, 8, ["user", "userId", "userList", "patients", "onUserDeleted"])
  ], 64))
}