
import { defineComponent, onMounted, Ref, ref } from 'vue';
import AppHeading from '@/components/HeadingComponent.vue';
import UserTable from '@/components/UserTable.vue';
import { useRoute } from 'vue-router';
import UserCreateModifyModal from '@/components/UserCreateModifyModal.vue';
import User, { UserTableDto } from '@/models/User';
import { userService } from '@/services/UserService';
import { ServiceError } from '@/services/util/ServiceError';
import { useToast } from 'primevue/usetoast';
import { useI18n } from 'vue-i18n';
import UserDeleteModal from '@/components/UserDeleteModal.vue';
import { PatientDto } from '@/models/Patient';
import { patientService } from '@/services/PatientService';

export default defineComponent({
  components: { AppHeading, UserTable, UserCreateModifyModal, UserDeleteModal },

  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const toast = useToast();
    const users: Ref<UserTableDto[] | undefined> = ref();
    const modalUser = ref({} as User);
    const modalUserId = ref('');
    const isEditModal = ref(false);
    const userModalRef = ref();
    const userDeleteModalRef = ref();
    const modalProfessionalPatientsList = ref([] as PatientDto[]);

    const loadUsers = async () => {
      const result = await userService.findAll();
      if (!(result instanceof ServiceError)) {
        users.value = result;
      }
    };
    onMounted(async () => {
      await loadUsers();
    });

    const onAddUser = () => {
      isEditModal.value = false;
      const newUser = {} as User;
      newUser.language = 'es';
      newUser.timezone = 'Europa/Madrid GMT +2';
      userModalRef.value?.openDialog(newUser);
    };
    const onEditUser = async (userId: string) => {
      const editUser = await userService.find(userId);
      if (editUser instanceof ServiceError) {
        toast.add({
          severity: 'error',
          summary: `${t('messages.errorSomethingWhenWrong')}. ${t('messages.errorSomethingWhenWrongDescription')}`,
          closable: false,
          life: 3000,
        });
        return;
      }
      isEditModal.value = true;
      userModalRef.value?.openDialog(editUser);
    };

    const onDeleteUser = async (userId: string, profesionalId: string) => {
      const user = await userService.find(userId);
      await getPatientsByProfessional(profesionalId);

      if (user instanceof ServiceError) {
        toast.add({
          severity: 'error',
          summary: `${t('messages.errorSomethingWhenWrong')}. ${t('messages.errorSomethingWhenWrongDescription')}`,
          closable: false,
          life: 3000,
        });
        return;
      }
      modalUser.value = user;
      modalUserId.value = user.id;
      userDeleteModalRef.value?.openDialog();
    };

    async function getPatientsByProfessional(userId: string) {
      await patientService
        .getPatientsByProfessionalId(userId)
        .then((response) => {
          modalProfessionalPatientsList.value = response;
        })
        .catch(() => {
          toast.add({
            severity: 'error',
            summary: `${t('messages.errorSomethingWhenWrong')}. ${t('messages.errorSomethingWhenWrongDescription')}`,
            closable: false,
            life: 3000,
          });
        });
    }

    return {
      userModalRef,
      userDeleteModalRef,
      route,
      users,
      modalUser,
      modalUserId,
      isEditModal,
      onAddUser,
      onEditUser,
      onDeleteUser,
      loadUsers,
      modalProfessionalPatientsList,
    };
  },
});
