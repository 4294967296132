import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between" }
const _hoisted_2 = { class: "p-input-icon-left p-col-4" }
const _hoisted_3 = { class: "dropdown-item" }
const _hoisted_4 = ["aria-label"]
const _hoisted_5 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_ctx.users)
    ? (_openBlock(), _createBlock(_component_DataTable, {
        key: 0,
        ref: "dt",
        filters: _ctx.filters,
        "onUpdate:filters": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filters) = $event)),
        value: _ctx.users,
        "data-key": "id",
        rows: 10,
        "filter-display": "menu",
        "paginator-template": "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink",
        paginator: _ctx.showPaginator,
        "responsive-layout": "scroll",
        "global-filter-fields": ['name', 'surname', 'email', 'phone'],
        "sort-field": "name",
        "sort-order": 1,
        "removable-sort": ""
      }, {
        header: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("span", _hoisted_2, [
              _cache[3] || (_cache[3] = _createElementVNode("i", {
                class: "icon-search",
                "aria-hidden": "true"
              }, null, -1)),
              _createVNode(_component_InputText, {
                modelValue: _ctx.filters['global'].value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filters['global'].value) = $event)),
                placeholder: _ctx.$t('table.search.users')
              }, null, 8, ["modelValue", "placeholder"])
            ]),
            _createVNode(_component_Button, {
              type: "button",
              class: "p-button p-component p-button-secondary p-button-medium p-button-icon",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.initFilters()))
            }, {
              default: _withCtx(() => [
                _cache[4] || (_cache[4] = _createElementVNode("i", {
                  class: "icon-trash_empty",
                  "aria-hidden": "true"
                }, null, -1)),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('table.clearFilters')), 1)
              ]),
              _: 1
            })
          ])
        ]),
        empty: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('table.not-found.users')), 1)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_Column, {
            field: "name",
            header: _ctx.$t('user.default'),
            sortable: ""
          }, {
            body: _withCtx((slotProps) => [
              _createVNode(_component_Avatar, {
                image: slotProps.data.picture,
                class: "p-mr-2 p-overflow-hidden",
                size: "small",
                shape: "circle"
              }, null, 8, ["image"]),
              _createElementVNode("span", _hoisted_3, _toDisplayString(slotProps.data.name) + " " + _toDisplayString(slotProps.data.surname), 1)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "email",
            header: _ctx.$t('person.email')
          }, {
            body: _withCtx((slotProps) => [
              _createTextVNode(_toDisplayString(slotProps.data.email), 1)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "roles",
            header: _ctx.$t('user.role'),
            showFilterMatchModes: false,
            showApplyButton: false
          }, {
            body: _withCtx((slotProps) => [
              _createTextVNode(_toDisplayString(_ctx.renderRoles(slotProps.data.roles)), 1)
            ]),
            filter: _withCtx(({ filterModel, filterCallback }) => [
              _createVNode(_component_Dropdown, {
                modelValue: filterModel.value,
                "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                placeholder: _ctx.$t('common.all'),
                options: _ctx.roleOptions,
                "option-label": "label",
                "option-value": "value",
                "filter-field": "roles",
                class: "p-column-filter",
                onChange: ($event: any) => (filterCallback())
              }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder", "options", "onChange"])
            ]),
            filterclear: _withCtx(() => _cache[5] || (_cache[5] = [])),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            header: _ctx.$t('table.actions')
          }, {
            body: _withCtx((slotProps) => [
              _withDirectives((_openBlock(), _createBlock(_component_Button, {
                class: "p-button-only-icon",
                onClick: ($event: any) => (_ctx.onEditUser(slotProps.data))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("i", {
                    class: "icon-edit",
                    "aria-label": _ctx.$t('common.edit'),
                    "aria-hidden": "true"
                  }, null, 8, _hoisted_4)
                ]),
                _: 2
              }, 1032, ["onClick"])), [
                [
                  _directive_tooltip,
                  _ctx.$t('common.edit'),
                  void 0,
                  { bottom: true }
                ]
              ]),
              _withDirectives((_openBlock(), _createBlock(_component_Button, {
                class: _normalizeClass(["p-button-only-icon", { 'p-disabled': slotProps.data.id == _ctx.userId }]),
                onClick: ($event: any) => (_ctx.onDeleteUser(slotProps.data))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("i", {
                    class: "icon-trash_full",
                    "aria-label": _ctx.$t('common.delete'),
                    "aria-hidden": "true"
                  }, null, 8, _hoisted_5)
                ]),
                _: 2
              }, 1032, ["class", "onClick"])), [
                [
                  _directive_tooltip,
                  _ctx.$t('common.delete'),
                  void 0,
                  { bottom: true }
                ]
              ])
            ]),
            _: 1
          }, 8, ["header"])
        ]),
        _: 1
      }, 8, ["filters", "value", "paginator"]))
    : _createCommentVNode("", true)
}