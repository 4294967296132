
import { UserTableDto } from '@/models/User';
import { UserReassign } from '@/models/UserReassign';
import { userService } from '@/services/UserService';
import { useToast } from 'primevue/usetoast';
import { computed, defineComponent, PropType, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { PatientDto } from '@/models/Patient';

export default defineComponent({
  emits: ['user-deleted'],
  props: {
    userId: {
      type: String,
      required: true,
    },
    userList: {
      type: Array as PropType<UserTableDto[]>,
    },
    patients: {
      type: Array as PropType<PatientDto[]>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const toast = useToast();
    const showDialog = ref(false);
    const userReassign = ref({} as UserReassign);
    const waitSubmit = ref(false);
    const showValidation = ref(false);
    const professionals = computed(() => props.userList);
    const havePatients = computed(() => props.patients.length > 0);
    const disableSubmit = computed(() => props.patients.length > 0 && !userReassign.value.userIdToReassign);

    const openDialog = () => {
      userReassign.value = {} as UserReassign;
      showValidation.value = false;
      showDialog.value = true;
    };

    const onSubmit = async () => {
      showValidation.value = true;
      await deleteUser();
    };

    async function deleteUser() {
      waitSubmit.value = true;
      if (disableSubmit.value) {
        return;
      }
      await userService
        .deleteProfessionalByUserId(props.userId, userReassign.value)
        .then(() => {
          toast.add({ severity: 'success', summary: `${t('messages.notifications.successDeleteUser')}`, life: 3000 });
          emit('user-deleted');
          showDialog.value = false;
        })
        .catch(() => {
          toast.add({
            severity: 'error',
            summary: `${t('messages.notifications.errorDeleteUser')}`,
            life: 3000,
          });
        })
        .finally(() => {
          waitSubmit.value = false;
        });
    }

    return {
      showDialog,
      waitSubmit,
      userReassign,
      showValidation,
      professionals,
      openDialog,
      onSubmit,
      havePatients,
      disableSubmit,
    };
  },
});
