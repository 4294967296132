
import { defineComponent, ref, computed, Ref, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { ServiceError } from '@/services/util/ServiceError';
import User, { UserFormDto, UserTableDto } from '@/models/User';
import { isFormValid, createUserForm, updateUserForm } from '@/validation/userForm';
import { useToast } from 'primevue/usetoast';
import inputHelper from '@/helpers/Input.helper';
import { userService } from '@/services/UserService';

export default defineComponent({
  emits: ['user-updated'],
  props: {
    modify: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    userList: {
      type: Array as PropType<UserTableDto[]>,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const toast = useToast();
    const { availableLocales } = useI18n();
    const showDialog = ref(false);
    const user: Ref<User> = ref({} as User);
    const userFormDto: Ref<UserFormDto> = ref({} as UserFormDto);
    const validatedForm = computed(() =>
      props.modify ? updateUserForm(userFormDto.value) : createUserForm(userFormDto.value, props.userList),
    );
    const valid = computed(() => isFormValid(validatedForm.value));
    const waitSubmit = ref(false);
    const showValidation = ref(false);
    const languageOptions = ref(inputHelper.getLangOptions(availableLocales));
    const userTypeOptions = ref(inputHelper.getUserTypeOptions(t));

    const rolesWithDisableOptions = computed(() => {
      return userTypeOptions.value.map((role) => {
        return {
          value: role.value,
          label: role.label,
          disable: user.value.roles && user.value.roles.includes(role.value),
        };
      });
    });

    const openDialog = (defaultUser: User) => {
      if (!defaultUser) {
        return;
      }
      showValidation.value = false;
      user.value = defaultUser;
      userFormDto.value = new UserFormDto(defaultUser);
      showDialog.value = true;
    };

    const onSubmit = async () => {
      showValidation.value = true;
      if (!valid.value) {
        return;
      }
      if (props.modify) {
        await modifyUser(userFormDto.value);
      } else {
        await createUser(userFormDto.value);
      }
    };

    async function createUser(createdUser: UserFormDto) {
      waitSubmit.value = true;
      const result = await userService.createUser(createdUser);
      waitSubmit.value = false;
      if (result instanceof ServiceError) {
        toast.add({
          severity: 'error',
          summary: `${t('messages.notifications.errorCreateUser')} ${t('messages.notifications.tryLater')}`,
          life: 3000,
        });
      } else {
        toast.add({ severity: 'success', summary: `${t('messages.notifications.successCreateUser')}`, life: 3000 });
        emit('user-updated');
        showDialog.value = false;
      }
    }
    async function modifyUser(modifiedUser: UserFormDto) {
      waitSubmit.value = true;
      const result = await userService.modify(user.value.id, modifiedUser);
      waitSubmit.value = false;
      if (result instanceof ServiceError) {
        toast.add({
          severity: 'error',
          summary: `${t('messages.notifications.errorEditUser')} ${t('messages.notifications.tryLater')}`,
          life: 3000,
        });
      } else {
        toast.add({ severity: 'success', summary: `${t('messages.notifications.successEditUser')}`, life: 3000 });
        emit('user-updated');
        showDialog.value = false;
      }
    }

    return {
      validatedForm,
      userFormDto,
      showDialog,
      waitSubmit,
      languageOptions,
      userTypeOptions,
      showValidation,
      openDialog,
      onSubmit,
      rolesWithDisableOptions,
    };
  },
});
